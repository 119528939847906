@use '../../styles/typo' as typo;

.Table {
    border-collapse: collapse;
    width: 100%;
    text-align: left;
    vertical-align: top;

    & th {
        @include typo.content_2;
    }

    & tr {
        border-bottom: 1px solid #e6e8ea;
    }

    & td {
        vertical-align: top;
        padding: 1rem;
    }
}

.HeaderCell {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
}

.SortingIcons {
    display: flex;
    flex-direction: column;
    height: 24px;
    width: 24px;
    justify-content: center;
    position: relative;

    & > span[data-direction] {
        position: absolute;
        display: block;
        height: 12px;
        width: 100%;
        background-color: white;

        &[data-direction='DESC'] {
            top: 0;
        }

        &[data-direction='ASC'] {
            bottom: 0;
        }
    }
}

.RowError {
    margin: 0.5rem 0 0.5rem 0.5rem;
    width: max-content;
}
