@use '../../styles/typo.scss' as typo;

.Alert {
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 2px;
    border-width: 1px;
    border-style: solid;
}

.Icon,
.Content {
    padding: 1rem;
}

.Icon {
    flex-shrink: 0;
}

.Content {
    width: 100%;
    font-size: 1rem;
    border-left-width: 1px;
    border-left-style: solid;
}

.Info {
    border-color: #a8bdd5;
    background-color: #dae5f3;
    color: #517596;

    & .Content {
        border-color: #a8bdd5;
    }
}

.Error {
    border-color: #d6b4b4;
    background-color: var(--error-bg-color);
    color: var(--error-text-color);

    & .Content {
        border-color: #d6b4b4;
    }
}

.Warning {
    border-color: #e6dbb7;
    background-color: var(--warning-bg-color);
    color: var(--warning-text-color);

    & .Content {
        border-color: #e6dbb7;
    }
}

.TwoColor {
    &.Warning {
        border-color: #cfcfd0;
        color: var(--warning-text-color);
    }

    .Content {
        border: none;
        background-color: #fff;
        @include typo.content_2;
    }
}
