@use '../../styles/typo' as typo;

.Pagination {
    @include typo.content_1_light;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 5px;

    &Mobile {
        justify-content: center;
    }

    &Mobile &Label {
        display: inline-block;
        padding: 0 0.75rem;
    }
}

.button {
    border: 1px solid #cfcfd0;
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    height: 24px;
    margin-right: 8px;
    overflow: hidden;
    text-align: center;
    width: 24px;
}
