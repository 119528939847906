@use '../../styles/typo' as typo;

.Panel {
    border-bottom: 1px solid #e6e8ea;
    border-top: 1px solid #e6e8ea;

    & + & {
        border-top: none;
    }

    &:not(:last-child) {
        margin-bottom: 1rem;
    }

    & + & {
        margin-top: -1rem;
    }
}

/*
.Collapsed {
}
*/

.Title {
    @include typo.content_2;
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 4rem;
    user-select: none;

    .Collapsed & {
        @include typo.content_1;

        &:hover {
            background: #f5f6f8;
        }
    }
}

.IconContainer {
    align-items: center;
    display: flex;
    font-weight: normal;
    justify-content: center;
    width: 3rem;

    .ArrowIcon {
        height: 1rem;
        width: 1rem;
    }
}

.Content {
    padding: 0 3rem;
}
