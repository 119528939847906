.IconContainer {
    border-radius: 50%;
    display: inline-flex;
    height: 24px;
    width: 24px;
    justify-content: center;
    align-items: center;

    & > .Icon {
        height: 14px;
        width: 14px;
    }

    &Transparent {
        background-color: #354052;

        & > .Icon {
            color: #a8bdd5;
        }
    }

    &Blue {
        background-color: var(--color-primary);

        & > .Icon {
            color: #3e5065;
        }
    }

    &Red {
        background-color: var(--error-bg-color);

        & > .Icon {
            color: var(--error-text-color);
        }
    }

    &Yellow {
        background-color: var(--warning-bg-color);

        & > .Icon {
            color: var(--warning-text-color);
        }
    }
}

.Tooltip {
    max-width: 320px;
}
