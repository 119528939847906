@use '../../styles/typo' as typo;

.RichText {
    @include typo.content_1;

    p {
        margin: 0 0 1rem 0;
    }

    h1 {
        @include typo.headline_0;
        margin: 0 0 1rem 0;
    }

    h2 {
        @include typo.headline_1;
        margin: 0 0 1rem 0;
    }

    h3 {
        @include typo.headline_2;
        margin: 0 0 1rem 0;
    }

    h4 {
        @include typo.headline_3;
        margin: 0 0 1rem 0;
    }

    h5 {
        @include typo.headline_4;
        margin: 0 0 1rem 0;
    }

    h6 {
        @include typo.headline_5;
        margin: 0 0 1rem 0;
    }

    ul {
        box-sizing: content-box;
        list-style: none;
        margin: 0 0 1rem 0;
        padding: 0;
    }

    ul li {
        list-style: none;
        padding-left: 1.5rem;
        position: relative;
    }

    ul li::before {
        color: #e30031;
        content: '–';
        display: inline-block;
        font-weight: bold;
        margin-left: -1.5rem;
        position: absolute;
        width: 1.5rem;
    }

    ul li p {
        margin-bottom: 0;
    }

    ul ul {
        margin: 0;
    }

    ul ul li::before {
        font-weight: normal;
    }

    ol {
        box-sizing: content-box;
        counter-reset: ol-counter;
        list-style: none;
        margin: 0 0 1rem 0;
        padding: 0;
    }

    ol li {
        counter-increment: ol-counter;
        list-style: none;
        padding-left: 1.5rem;
        position: relative;
    }

    ol li::before {
        color: #e30031;
        content: counter(ol-counter);
        display: inline-block;
        margin-left: -1.5rem;
        position: absolute;
        width: 1.5rem;
    }

    ol li p {
        margin-bottom: 0;
    }

    ol ol {
        counter-reset: ol-ol-counter;
        margin: 0;
    }

    ol ol li {
        counter-increment: ol-ol-counter;
    }

    ol ol li::before {
        content: counter(ol-ol-counter, lower-alpha);
    }

    li:not(:first-child),
    ul ul,
    ol ol {
        margin-top: 0.25rem;
    }

    a {
        @include typo.link_1;
    }
}
