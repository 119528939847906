.ChangeLanguageForm {
    & label {
        width: 50%;
    }
}

.AlertBox {
    margin: 0.5rem 0 1rem 0;
}

.ChangeAlert {
    & + & {
        margin-top: 1rem;
    }
}

.FormButtons {
    margin-top: 0.5rem;
    width: 75%;
    display: flex;
    gap: 1rem;
    justify-content: stretch;

    & > div:first-child:last-child {
        max-width: 210px;
    }
}
