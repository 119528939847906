.FooterDesktop {
    box-sizing: border-box;
    color: #a0a0a1;
    font-family: 'Frutiger', sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 13px;
    padding: 1rem 1.5rem;
    text-align: center;
    width: 100%;

    &Link,
    a {
        padding: 0;
        color: var(--color-primary);
        cursor: pointer;
        font-size: 12px;
        text-decoration: underline;
        border: 0;
        background: transparent;

        &:hover {
            color: #e30031;
            text-decoration: underline;
        }
    }
}
