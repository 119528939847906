@use '../../styles/typo.scss' as typo;

.Roles {
    & > h2,
    & > p {
        margin-bottom: 1rem;
    }

    & > h2 {
        @include typo.subtitle_1;
        text-transform: uppercase;
        font-weight: bold;
    }

    & > p {
        @include typo.content_1;
    }
}
