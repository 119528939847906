.MainContent {
    margin: 0.5rem 0 1.5rem 0;

    & > span {
        color: var(--color-red);
    }
}

.Alert + .Alert {
    margin-top: 1.5rem;
}

.AlertTitle {
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.AlertContent > p {
    margin: 0.5rem 0;
}

.ButtonContainer {
    display: flex;
    gap: 0.5rem;
    width: 50%;
}
