.PasswordFields {
    display: flex;
    gap: 1rem;
    padding: 0;
    margin-bottom: 0.5rem;
    border: none;
}

.Input {
    flex-grow: 1;
    flex-basis: 50%;
}

.ButtonContainer {
    margin-top: 1rem;
    width: 75%;
    display: flex;
    gap: 1rem;
    justify-content: stretch;

    & > div:first-child:last-child {
        max-width: 210px;
    }
}
