@use '../../styles/typo' as typo;

.EmptyList {
    color: #9a9fa8;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
}

.EmptyIcon {
    height: 3rem;
    width: 3rem;
    margin-bottom: 0.5rem;
}

.EmptyWarningTitle {
    @include typo.content_1;
    font-weight: bold;
}

.EmptyWarningDetails {
    @include typo.content_1_light;
    max-width: 66%;
    text-align: center;
}
