.CreateServiceAddressForm {
    margin: 1.5rem;

    & > [class*='ButtonContainer'] {
        margin-top: 2.5rem;
        width: 33%;
    }
}

.Fields {
    display: flex;
    gap: 1rem;

    & > label {
        flex-basis: 50%;
    }
}
