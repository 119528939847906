.App {
    height: 100%;
    display: grid;
    overflow: hidden;
    grid-template-columns: min-content 1fr;
    grid-template-rows: var(--header-height) auto 1fr;
    grid-template-areas:
        'hd hd'
        'nv banner'
        'nv main';

    &Mobile {
        grid-template-rows: var(--header-mobile-height) auto 1fr;
    }

    &Header {
        grid-area: hd;
        z-index: 1;
    }

    &Navigation {
        grid-area: nv;
    }

    &Banner {
        grid-area: banner;
    }

    &Content {
        grid-area: main;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1.5rem 5rem 0 1.5rem;
        overflow: auto;
    }

    &ContentMobile {
        padding: 0;
    }

    &Footer {
        margin-top: auto;
    }
}
