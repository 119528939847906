@use '../../styles/typo' as typo;

.PermissionsList {
    padding: 0;
    column-count: 1;

    .PermissionCheckBox {
        @include typo.content_1;
        line-height: 16px;
    }

    .LastModifiedNote {
        margin-top: 0.125rem;
        @include typo.content_3;
    }

    .PermissionContainer {
        break-inside: avoid-column;
        margin-bottom: 0.75rem;
    }
}

.ListItem {
    list-style: none;
    padding: 0;
    margin: 0.25rem 0;
    @include typo.content_1;
    display: flex;
    align-items: center;

    & > .IconWrapper {
        display: inline-flex;
        height: 24px;
        margin-right: 0.5rem;
        flex-shrink: 0;
        align-self: flex-start;
        align-items: center;

        .EnabledIcon {
            color: #006008;
            height: 1rem;
            width: 1rem;
        }

        .DisabledIcon {
            color: var(--color-red);
            height: 1rem;
            width: 1rem;
        }
    }
}
