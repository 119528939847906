@use '../../styles/typo' as typo;

$blur: 15px;

.Container {
    box-sizing: border-box;
    height: 0;
    margin: 0 0 1rem 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;
    margin-bottom: 1rem;
}

.Blur {
    background: #f5f6f8 50% 50% repeat;
    background-size: cover;
    bottom: $blur * -2;
    filter: blur($blur);
    left: $blur * -2;
    position: absolute;
    right: $blur * -2;
    top: $blur * -2;
}

.Video {
    height: 100%;
    object-fit: contain;
    position: absolute;
    width: 100%;
}

.Title {
    @include typo.content_2;
    margin: 0 0 0.5rem 0;
}

.Description {
    @include typo.content_1;
}

.PlayButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 90px;
    width: 90px;
    padding: 0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(rgba(0, 0, 0), rgba(0, 0, 0, 0.4));
    border: none;
    cursor: pointer;

    &:hover {
        background: linear-gradient(#537da3, #8caac6);
    }

    & > .PlayTriangle {
        display: grid;
        margin-left: 5px;
        margin-top: 1px;

        &::before {
            content: '';
            display: block;
            grid-row: 1;
            grid-column: 1;
            background-color: rgba(0, 0, 0, 0.7);
            clip-path: polygon(0 0, 100% 50%, 0 101%);
            height: 21px;
            width: 16px;
        }

        &::after {
            content: '';
            display: block;
            grid-row: 1;
            grid-column: 1;
            background-image: linear-gradient(#fff 40%, #999);
            clip-path: polygon(0 0, 100% 50%, 0 100%);
            height: 20px;
            width: 16px;
        }
    }
}
