@use '../../styles/typo' as typo;

.Row {
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    padding: 0.5rem 2rem 0.5rem 0;
    width: 100%;
    border-bottom: 1px solid #e1e3e9;

    &Status {
        background-color: var(--color-primary);
        border-radius: 50%;
        display: inline-block;
        height: 0.5rem;
        width: 0.5rem;

        &Column {
            text-align: right;
            width: 1rem;
            flex-shrink: 0;
            align-self: center;
        }
    }

    &Title {
        @include typo.content_1;
    }

    &Meta {
        @include typo.content_1_light;
        overflow-wrap: anywhere;
    }

    &Clickable {
        cursor: pointer;

        &:hover {
            background-color: #f5f6f8;
        }
    }

    &Blue {
        background-color: #dae5f3;
        border-bottom-color: rgba(#a8bdd5, 0.5);

        &.RowClickable:hover {
            background-color: #b7cbdf;
        }

        & .RowTitle {
            font-weight: bold;
        }

        & .RowTitle,
        & .RowMeta {
            color: #517596;
        }
    }

    &Yellow {
        background-color: var(--warning-bg-color);
        border-bottom-color: rgba(#e6dbb7, 0.5);

        &.RowClickable:hover {
            background-color: #ecdbb0;
        }

        & .RowTitle {
            font-weight: bold;
        }

        & .RowTitle,
        & .RowMeta {
            color: var(--warning-text-color);
        }
    }

    &Red {
        background-color: var(--error-bg-color);
        border-bottom-color: rgba(#d6b4b4, 0.5);

        &.RowClickable:hover {
            background-color: #d4acad;
        }

        & .RowTitle {
            font-weight: bold;
        }

        & .RowTitle,
        & .RowMeta {
            color: var(--error-text-color);
        }
    }
}

.RowSingleLine {
    align-items: center;

    .RowTitle {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.MainColumn {
    flex-grow: 1;
    min-width: 0;
}

.TagsColumn {
    display: flex;
    flex-shrink: 0;
}

.Tag + .Tag {
    margin-left: 0.5rem;
}

.IconColumn {
    flex-shrink: 0;
}
