@use '../../styles/typo' as typo;

.CompanyInfo {
    @include typo.headline_5;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
}

.HighlightedText {
    color: var(--color-primary);
}
