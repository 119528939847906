@use '../../styles/typo.scss' as typo;

.FeaturePreviewMobile {
    width: 100%;
    padding: 1rem 1rem 3rem 1rem;
    background-color: #fff;

    &Warning {
        display: flex;
        align-items: center;

        margin: 0 0 1rem 0;

        color: var(--warning-text-color);
        border: 1px solid #e6dbb8;
        background-color: var(--warning-bg-color);
    }

    &WarningIconContainer {
        padding: 1rem;
    }

    &WarningIcon {
        height: 24px;
        width: 24px;
        margin: 0 auto;
        color: var(--warning-text-color);
    }

    &WarningBody {
        padding: 1rem;
        border-left: 1px solid #e6dbb8;
    }

    &Description {
        & > p:not(:last-child) {
            margin-bottom: 1rem;
        }
    }
}
