.ButtonGroup {
    & > div:not(:first-child):not(:last-child) > button {
        border-radius: 0 !important;
    }

    & > div:first-child > button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    & > div:last-child > button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
