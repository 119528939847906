@use '../../styles/typo' as typo;

@mixin page {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    padding: 4rem 8.5rem;
    width: 66rem;
    box-sizing: border-box;
}

@mixin title {
    @include typo.headline_0;
    margin: 0 0 2rem 0;
}

.Page {
    @include page;

    &Title {
        @include title;
    }
}
