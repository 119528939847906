@use '../../styles/typo' as typo;

.Tag {
    border-radius: 4px;
    box-sizing: border-box;
    cursor: default;
    display: inline-block;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 15px;
    max-width: 100%;
    overflow: hidden;
    padding: 5px 11px;
    text-align: center;
    text-decoration: none;
    text-overflow: ellipsis;
    user-select: none;
    white-space: nowrap;

    &IsClickable:hover {
        cursor: pointer;
        text-decoration: underline;
    }

    &IsMultiline {
        white-space: normal;
    }

    &IsBlue {
        background-color: #f5f7fb;
        color: var(--color-primary);
    }

    &IsGreen {
        background-color: #edf1e3;
        color: #6da39d;
    }
}

.Tag {
    &Mobile {
        padding: 8px 12px;
        font-size: 16px;
    }
}
