@use '../../styles/typo' as typo;

.Tile {
    display: flex;
    flex-direction: column;
    height: 385px;
    width: 240px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);

    & > .ImageContainer {
        background-color: #8caac6;
    }

    & > .ImageContainer,
    & > .ImageContainer > img {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        width: 100%;
        height: 124px;
    }
}

.TileContent {
    flex-grow: 1;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    @include typo.content_1;

    & > h1 {
        @include typo.content_2;
        margin-bottom: 1rem;
    }

    & > p {
        flex-grow: 1;
        max-height: 142px;
    }
}

.TilesContainer {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    margin: 2rem 0;
}
