@use '../../styles/typo' as typo;
@use '../Page/Page.module' as page;

.UserManagement {
    & > h1 {
        @include typo.headline_1;
        margin-bottom: 0.5rem;
    }

    & > .Note {
        @include typo.content_1;
    }
}

.CompanyInfoError {
    padding: 1rem 0;
}

.UserPermissions {
    @include page.page;
    padding: 2rem;
    width: 72rem;
}

.TOTPWall {
    width: 74rem !important;
}

.TopBar {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    margin-bottom: 1.5rem;
    justify-items: center;

    & > div:last-child {
        justify-self: end;
    }
}
