@use '../../styles/typo' as typo;
@use '../Page/Page.module' as page;

.Message {
    position: relative;
    @include page.page;
    padding-top: 6rem;

    & > nav {
        position: absolute;
        top: 1.5rem;
        left: 2rem;
    }

    &Title {
        @include page.title;
        margin: 0.5rem 0;
    }

    &Mobile {
        width: 100%;
        padding: 1rem;
    }
}

.Tag {
    margin-right: 0.5rem;
}

.ReleaseDate {
    @include typo.content_1_light;
    margin-bottom: 2.5rem;
    display: block;
}
