.Container {
    width: 100%;
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.Hidden {
    display: none;
}
