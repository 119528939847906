@use '../../styles/typo.scss' as typo;

.RestrictionBanner {
    background-color: var(--warning-bg-color);
    color: var(--warning-text-color);
    display: flex;
    padding: 0.5rem 1rem;
    font-size: 14px;
    align-items: center;
    gap: 0.5rem;

    & > span {
        background-color: var(--warning-text-color);
        border-radius: 50%;
        display: inline-block;
        height: 24px;
        width: 24px;
    }

    & .LockIcon {
        color: var(--warning-bg-color);
        height: 14px;
        width: 14px;
        margin: 0 auto;
        margin-top: 4px;
    }
}
