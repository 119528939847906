$tooltip-height: 40px;
$tooltip-arrow-size: 6px;

.SupportBar {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.32);
    position: fixed;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 48px;

    &Icon {
        color: #354052;
        height: 24px;
        width: 24px;
    }

    &Tooltip {
        background-color: #354052;
        border-radius: 5px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
        color: #fff;
        display: none;
        font-family: 'Frutiger', sans-serif;
        font-size: 16px;
        height: $tooltip-height;
        letter-spacing: 0;
        line-height: $tooltip-height;
        white-space: nowrap;
        padding: 0 16px;
        position: absolute;
        right: calc(100% + 16px);

        &::after {
            border-bottom: $tooltip-arrow-size solid transparent;
            border-left: $tooltip-arrow-size solid #354052;
            border-top: $tooltip-arrow-size solid transparent;
            box-sizing: border-box;
            content: '';
            display: block;
            height: 0;
            left: 100%;
            position: absolute;
            top: $tooltip-height * 0.5 - $tooltip-arrow-size;
            width: 0;
        }
    }

    &Item {
        align-items: center;
        cursor: pointer;
        display: flex;
        height: 48px;
        justify-content: center;
        position: relative;
        user-select: none;
        width: 48px;
    }

    &Trigger {
        position: absolute;
        right: 0;
        bottom: 0;

        height: 60px;
        width: 60px;

        background-color: #6794bc;
        border-radius: 50%;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);

        cursor: pointer;

        z-index: 10;
    }

    &TriggerIcon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        height: 42px;
        width: 42px;

        color: #fff;
    }

    &:not(&Mobile) &Item:hover {
        background: #e1e3e9;
    }

    &:not(&Mobile) &Item:hover &Tooltip {
        display: block;
    }
}

.SupportBar {
    &Mobile {
        position: relative;
        left: 50%;
        bottom: 5px;
        transform: translateX(-50%);

        padding-top: 10px;
        padding-bottom: 57px;
        border-radius: 50px;

        z-index: 9;
    }

    &Mobile &Icon {
        height: 30px;
        width: 30px;
    }

    &Mobile &Tooltip {
        display: none;
    }
}

.SupportBarContainer {
    z-index: 1;

    &Empty {
        display: none;
    }

    &Mobile {
        position: fixed;
        right: 24px;
        bottom: 24px;

        width: 60px;
    }
}

.SupportBarContainerCollapsed .SupportBar {
    display: none;
}
