@use '../../styles/typo' as typo;

.NameCell,
.EmailCell {
    @include typo.content_2;
}

.MainCell {
    position: relative;
}

.MainCellActions {
    margin-top: auto;
    position: absolute;
    bottom: 1rem;
    display: flex;
    gap: 0.5rem;

    & > div[class*='ButtonContainer'] {
        flex-shrink: 0;
    }

    &Column {
        flex-direction: column;
    }
}

.LastModifiedCell {
    @include typo.content_1;

    & .LastModifiedEditing {
        @include typo.content_1_light;
    }
}
