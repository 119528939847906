.FooterMobile {
    box-sizing: border-box;

    width: 100%;

    padding: 1.5rem 0;

    text-align: center;
    font-family: 'Frutiger', sans-serif;
    font-size: 12px;
    line-height: 1.2;
    letter-spacing: 0;
    color: #a0a0a1;
    background-color: #f5f6f8;

    & > div {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    &Links {
        margin-top: 0.5rem;
        padding-bottom: 0.8rem;
        border-bottom: 1px solid var(--color-hairline);
    }

    &Link {
        display: inline-block;
        padding: 0.5rem 0.5rem;
        color: var(--color-text-primary);
        cursor: pointer;
        font-size: 12px;
        border: 0;
        background: transparent;
        text-decoration: none;

        &:hover {
            color: #e30031;
        }
    }

    &LngSelectorContainer {
        margin-top: 0.8rem;
    }

    &LngSelector {
        display: inline-block;
        padding: 0.5rem 0.5rem;
        color: var(--color-primary);
        cursor: pointer;
        font-size: 12px;
        border: 0;
        background: transparent;
        text-decoration: underline;

        &:hover {
            color: #e30031;
        }

        &:disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    &LngSelectorInner {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        gap: 6px;
    }
}
