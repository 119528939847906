@use '../../styles/typo.scss' as typo;

.ContactInformation {
    padding: 2.5rem;
}

.SubTitle {
    @include typo.content_2_uppercase;
    display: block;
}

.InfoSection,
.InfoAlert {
    margin-top: 1rem;
}

.InfoSection {
    @include typo.content_1;
}

.CCInput {
    display: block;
    margin-top: 1rem;
}
