.FilterButtons {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    &.Blue {
        --filter-buttons-main-color: var(--color-primary);
    }

    & > .Option {
        display: inline-flex;
        align-items: center;
        padding: 3px 0.5rem;
        border-radius: 1rem;
        border: 1px solid var(--filter-buttons-main-color);
        background-color: var(--filter-button-bg-color);
        color: var(--filter-button-text-color);
        cursor: pointer;
        white-space: nowrap;

        &.Active {
            --filter-button-bg-color: var(--filter-buttons-main-color);
            --filter-button-text-color: white;
        }

        &:not(.Active) {
            --filter-button-bg-color: transparent;
            --filter-button-text-color: var(--filter-buttons-main-color);
        }

        & > .DismissIcon {
            height: 1rem;
            width: 1rem;
        }
    }
}
