.Stoerer {
    align-items: center;
    background: rgba(42, 51, 66, 0.7);
    bottom: 0;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 9999;
}

.Box {
    background: #fff;
    border-radius: 4px;
    border-top: 8px solid #fff;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    max-width: 616px;
    min-height: 120px;
    min-width: 320px;
    padding: 24px 24px 24px 120px;
    position: relative;
}

.Close {
    color: #354052;
    cursor: pointer;
    height: 24px;
    position: absolute;
    right: 13px;
    top: 13px;
    width: 24px;
}

.DialogIcon {
    height: 60px;
    left: 30px;
    position: absolute;
    top: 30px;
    width: 60px;
}

.Title {
    color: #354052;
    font-family: 'Frutiger', sans-serif;
    font-size: 20px;
    font-weight: bold;
    height: 32px;
    letter-spacing: 0;
    line-height: 32px;
    margin-bottom: 8px;
}

.Message,
.More {
    color: #354052;
    font-family: 'Frutiger', sans-serif;
    font-size: 16px;
    font-weight: lighter;
    letter-spacing: 0;
    line-height: 22px;
}

.More {
    display: none;
}

.ReadMore {
    align-items: center;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 22px);
    color: var(--color-primary);
    cursor: pointer;
    display: flex;
    font-size: 16px;
    line-height: 22px;
    padding-top: 22px * 1.5;
    position: relative;
    top: 22px * -1;
    width: 100%;

    &:hover {
        color: var(--color-red);
    }
}

.ReadMoreIcon {
    height: 16px;
    margin-right: 5px;
    width: 16px;
}

.Expanded {
    .More {
        display: block;
    }

    .ReadMore {
        padding-top: 22px * 0.5;
        top: 0;
    }
}

.Actions {
    border-top: 1px solid #e1e3e9;
    margin: 24px -24px -8px -120px;
    padding: 16px 24px 0;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

.InfoType {
    border-color: var(--color-primary);

    .DialogIcon {
        color: var(--color-primary);
    }
}

.WarningType {
    border-color: #ffd12b;

    .DialogIcon {
        color: #ffd12b;
    }
}

.ErrorType {
    border-color: var(--color-red);

    .DialogIcon {
        color: var(--color-red);
    }
}
