.TransportDetailsForm {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem 1.5rem;
    margin-bottom: 1.5rem;
}

.ShippingDateInput {
    grid-row: 4;
}
