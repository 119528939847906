.ContentHeaderMobile {
    flex-shrink: 0;

    width: 100%;

    position: relative;
    padding: 13px 18px 9px;

    font-size: 17px;
    font-weight: 700;

    overflow: hidden;
    text-overflow: ellipsis;

    border-bottom: 3px solid var(--color-primary);
    background-color: #fff;

    &Title {
        text-transform: uppercase;
    }

    &NavIconContainer {
        position: absolute;
        top: 8px;
        left: 1rem;

        height: 32px;
        width: 32px;

        border-radius: 4px;
        border: 1px solid #cfd1d7;
        background-color: #fff;

        cursor: pointer;
    }

    &NavIcon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        color: var(--color-primary);
    }

    &WithBackButton {
        padding-left: 60px;
    }
}
