@use '../../../styles/typo' as typo;

.Radio {
  @include typo.content_3;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;

  input {
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 1rem;
    -webkit-appearance: none;
    appearance: none;
    font: inherit;
    color: white;
    background-color: white;
    border: 1px solid #cfd1d6;
    width: 1rem;
    height: 1rem;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    border-radius: 50%;

    &:checked {
      background-color: var(--color-primary);
      border-color: var(--color-primary);
    }

    &:disabled {
      background-color: #a0a0a1;
    }

    &::before {
      content: "";
      width: 0.35rem;
      height: 0.35rem;
      border-radius: 50%;
      transform: scale(0);
      transform-origin: bottom left;
      background-color: white;
    }

    &:checked::before {
      transform: scale(1);
    }
  }

  &.ReadOnly {
    input {
      border-color: #f5f7fb;
      background-color: #f5f7fb;
    }

    input::before {
      background-color: #a0a0a1;
    }
  }

  a {
    color: var(--color-primary);

    &:hover {
      color: var(--color-red);
    }
  }
}

.RadioGroup {
  margin-bottom: 1rem;
}

.TransparentButton {
  color: #6994bb;
  background-color: white;
  border: none;
}

.TransparentButton:hover {
  color: white;
  background-color: #6994bb;
}