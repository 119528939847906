@use '../../styles/typo.scss' as typo;

.Contact {
    border-bottom: 1px solid #e1e3e9;
    user-select: none;
    background-color: white;
}

.Summary {
    align-items: center;
    display: flex;
    gap: 32px;
    padding: 24px;
    cursor: pointer;

    & > .Image {
        align-self: flex-start;
        border-radius: 50%;
        height: 80px;
        width: 80px;
    }

    & > .Text {
        flex-grow: 1;
    }

    & > .ArrowIcon {
        flex-shrink: 0;
    }
}

.Name {
    @include typo.headline_5;
    letter-spacing: 0;
}

.Role {
    @include typo.content_1;
    letter-spacing: 0;
    margin-bottom: 8px;
}

.Tag {
    margin-right: 0.5rem;
}

.Detail {
    @include typo.content_1;
    display: none;
    letter-spacing: 0;
    padding: 0 24px 24px (80px + 24px + 32px);

    & > div,
    & > a {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: 0.25rem;

        &:last-child {
            margin-top: 1rem;
            margin-bottom: 0;
        }
    }

    & a {
        @include typo.link_1;
    }
}

.DetailIcon {
    height: 16px;
    width: 16px;
    color: var(--color-text-primary);
}

.ContactMobile .DetailIcon {
    color: var(--color-text-primary);
}

.Expanded {
    .Detail {
        display: block;
    }
}
