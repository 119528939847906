.AppLoadingIndicator {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    .Container {
        height: 40%;
        gap: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    &.Global {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        background: var(--color-page-bg);
        z-index: 1000;

        & > .Container {
            max-height: 400px;
        }
    }

    &.AppContent {
        align-self: center;

        & > .Container {
            max-height: 300px;
        }
    }

    .Logo {
        width: 100%;
    }
}
