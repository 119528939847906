@mixin input_field {
    background-color: #fff;
    border: 1px solid #cfcfd0;
    border-radius: 4px;
    box-shadow: inset 1px 1px 4px 0 rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    color: #354052;
    display: block;
    font-family: 'Frutiger', sans-serif;
    font-size: 16px;
    height: 32px;
    letter-spacing: 0;
    width: 100%;
    font-weight: normal;
}

.Input {
    @include input_field;
    padding: 0 8px;

    &::placeholder {
        color: #a0a0a1;
        font-family: 'Frutiger', sans-serif;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 15px;
    }

    &:focus {
        outline: 2px solid rgba(var(--color-primary), 0.3);
    }

    &:read-only {
        background-color: #f5f7fb;
        border: none;
        box-shadow: none;
    }

    &WithIcon,
    &WithStartIcon {
        padding-left: 32px;

        & ~ .Icon {
            left: 8px;
        }
    }

    &WithEndIcon {
        padding-right: 32px;

        & ~ .Icon {
            right: 8px;
        }
    }

    &WithError {
        border-color: var(--color-red);

        &:focus {
            outline-color: rgba(var(--color-red), 0.3);
        }
    }
}

.ErrorIndicator {
    right: 0.5rem;

    .InputWithEndIcon ~ & {
        right: 1.75rem;
    }
}

.Icon {
    fill: #354052;
    height: 16px;
    position: absolute;
    top: 8px;
    width: 16px;
}
