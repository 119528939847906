@use '../../styles/typo' as typo;

.Separator {
    box-sizing: border-box;
    display: flex;
    min-height: 3rem;
    padding: 1rem 0;

    &::before {
        background: url('data:image/gif;base64,R0lGODlhAQABAIABAObo6v///yH+EUNyZWF0ZWQgd2l0aCBHSU1QACH5BAEKAAEALAAAAAABAAEAAAICRAEAOw==')
            50% 50% repeat-x;
        content: '';
        display: block;
        width: 50%;
    }

    &::after {
        background: url('data:image/gif;base64,R0lGODlhAQABAIABAObo6v///yH+EUNyZWF0ZWQgd2l0aCBHSU1QACH5BAEKAAEALAAAAAABAAEAAAICRAEAOw==')
            50% 50% repeat-x;
        content: '';
        display: block;
        width: 50%;
    }

    &Item {
        @include typo.content_1_light;
        padding: 0 1rem;
        white-space: nowrap;
    }
}
