@use '../Page/Page.module' as page;
@use '../../styles/typo' as typo;

.DownloadsOverview {
    width: 100%;
}

.PageTitle {
    margin-bottom: 1rem;
}

.GoToListCard {
    align-self: center;
    justify-self: center;
    height: 128px;
    width: 128px;
    display: grid;

    & .GoToListLink {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include typo.link_1;
        text-decoration: underline;
    }

    & .GoToIcon {
        margin-top: 0.5rem;
        margin-bottom: 0.25rem;
        height: 1.875rem;
        width: 1.875rem;
    }
}
