@use '../../styles/typo' as typo;

$blur: 15px;

.Wrapper {
    margin-bottom: 1rem;
}

.Container {
    box-sizing: border-box;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;
    margin-bottom: 1rem;
}

.Blur {
    background: #f5f6f8 50% 50% repeat;
    background-size: cover;
    bottom: $blur * -2;
    filter: blur($blur);
    left: $blur * -2;
    position: absolute;
    right: $blur * -2;
    top: $blur * -2;
}

.Image {
    height: 100%;
    object-fit: contain;
    position: absolute;
    width: 100%;
}

.Title {
    @include typo.content_2;
    margin-bottom: 0.5rem;
}

.Description {
    @include typo.content_1;
}
