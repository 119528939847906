@use '../../styles/typo.scss' as typo;

.EmailServicesSpace {
    padding-right: 200px;
}

.EmailServices {
    & > h2,
    & > p {
        margin-bottom: 1rem;
    }

    & > h2 {
        @include typo.subtitle_1;
        text-transform: uppercase;
        font-weight: bold;
    }

    & > p {
        @include typo.content_1;
    }
    & > form {
        margin-bottom: 0.5rem;

        & > label {
            flex-basis: calc(50% - 0.5rem);
        }
    }
}

.FormButtons {
    margin-top: 1rem;
    width: 75%;
    display: flex;
    gap: 1rem;
    justify-content: stretch;

    & > div:first-child:last-child {
        max-width: 210px;
    }
}
