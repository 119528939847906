@use '../../styles/typo' as typo;

.GlobalErrorBoundary {
    display: grid;
    align-content: center;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    flex-grow: 1;
    @include typo.content_1;
    position: absolute;
    width: 100%;
    height: 100%;

    .Logo {
        width: 100%;
    }
}

.Buttons {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
