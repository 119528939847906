@use '../Page/Page.module' as page;

.TableContainer {
  @include page.page;
  padding: 1rem 0 0 0;
  box-shadow: none;
  width: initial;
}

.Heading {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.TopSection {
  margin-top: 2rem;
  border-top: 1px solid #f0f0f0;
  margin-bottom: 3rem;
}

.SectionCreate {
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 80% 20%;
}

.lastCell {
  width: 5%;
}

.lastCellActions {
  display: flex;
  gap: 0.5rem;
}


.EmptyHeadline {
  font-size: 1.375rem;
  line-height: 1.45;
  margin-bottom: 0.5rem;
}

.EmptySubHeadline {
  color: #9A9FA8;
  font-size: 1rem;
  line-height: 1.5;
}


.CreateButtonSection {
  display: flex;
  align-items: end;
  justify-content: flex-end;
}

.ModalCustomWidth {
  & > div {
    & > section {
      width: 800px;

      & > div:nth-of-type(2) {
        overflow-y: unset;
      }
    }
  }
}