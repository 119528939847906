.Block {
    margin: 0 0 1rem 0;
}

.Separator {
    &[data-size='small'] {
        height: 20px;
    }

    &[data-size='medium'] {
        height: 50px;
    }

    &[data-size='large'] {
        height: 100px;
    }
}
