.SidePanel {
    background: #fff;
    bottom: 0;
    box-shadow: -3px 0 8px 0 rgba(53, 64, 82, 0.08);
    position: absolute;
    right: 0;
    top: var(--header-height);
    width: 400px;
    z-index: 50;
    display: grid;
    grid-template: min-content auto / 100%;

    &Title {
        border-bottom: 1px solid #e6e8ea;
        color: #354052;
        display: block;
        font-family: 'Frutiger', sans-serif;
        font-size: 22px;
        letter-spacing: 0;
        line-height: 24px;
        padding: 16px 24px;
    }

    &Close {
        color: #354052;
        cursor: pointer;
        height: 24px;
        position: absolute;
        right: 16px;
        top: 16px;
        width: 24px;
        z-index: 10000;
    }

    &Content {
        overflow-y: auto;
        scrollbar-width: thin;
    }
}

.SidePanel {
    &Mobile {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }

    &Mobile &Title {
        padding: 24px;
    }

    &Mobile &Close {
        top: 24px;
    }
}
