@use '../../styles/typo' as typo;

.SessionErrorBoundary {
    @include typo.content_1;
}

.Link {
    @include typo.link_1;

    & {
        margin-top: 16px;
    }
}

.container {
    width: 80%;
    min-width: 300px;
    margin: 32px auto;
    text-align: center;
}