@use '../../styles/typo' as typo;

.Terminals {

}

.Last {
  text-align: right;
}

.Action {
  cursor: pointer;
  @include typo.link_1;
}

.Search {
  margin: 0 !important;
  width: 100%;
}

.EmptyHeadline {
  font-size: 1.375rem;
  line-height: 1.45;
  margin-bottom: 0.5rem;
}

.EmptySubHeadline {
  color: #9A9FA8;
  font-size: 1rem;
  line-height: 1.5;
}