@use 'sass:math';
@use '../../styles/typo' as typo;

$button-size: 2rem;
$button-icon-size: 16px;
$button-size-small: 1.5rem;
$button-icon-size-small: 12px;

.ButtonContainer {
  position: relative;
  display: inline-block;
}

.Tooltip {
  position: absolute;
  right: 0;
  top: 39px;
  z-index: 1;
  background-color: #6994bb;
  border-color: #6994bb;
  box-sizing: border-box;
  white-space: nowrap;
  padding: 1px 5px;
  color: white;
  font-size: 13px;
}

.Tooltip::before {
  position: absolute;
  content: "";
  border-width: 9px;
  border-style: solid;
  border-color: rgba(255, 0, 0, 0) rgba(255, 0, 0, 0) #6994bb rgba(255, 0, 0, 0);
  top: -17px;
  right: 10px;
}

.Button {
  @include typo.button_1;

  align-items: center;
  border: 1px solid;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  width: 100%;
  gap: 0.5rem;
  height: $button-size;
  justify-content: center;
  outline: 0;
  padding: 0 0.5rem;
}

.Primary {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: #fff;

  &:hover,
  &:focus {
    background-color: #8caac6;
    border-color: #8caac6;
  }

  &:active {
    background-color: #537da3;
    border-color: #537da3;
  }

  &:disabled {
    background-color: #e1e3e9;
    border-color: #e1e3e9;
    cursor: default;
  }
}

.Secondary {
  background-color: #fff;
  border-color: #cfcfd0;
  color: var(--color-primary);

  &:hover,
  &:focus {
    background-color: #f5f6f8;
  }

  &:active {
    background-color: #dae5f3;
    border-color: #a8bdd5;
  }

  &:disabled {
    background-color: #fff;
    border-color: #cfcfd0;
    cursor: default;
    opacity: 0.5;
  }
}

.ButtonIcon {
  display: inline-block;
  flex-shrink: 0;
}

.Small {
  font-size: 12px;
  height: $button-size-small;
  line-height: $button-size-small;
  min-width: $button-size-small;
  padding: 0 0.25rem;

  & > .ButtonIcon {
    height: $button-icon-size-small;
    width: $button-icon-size-small;
  }
}

.Medium {
  & > .ButtonIcon {
    height: $button-icon-size;
    width: $button-icon-size;
  }
}

.Block {
  width: 100%;
}

.Multiline {
  height: auto !important;
  padding: 0.5rem;
}
