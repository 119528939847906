$tooltip-height: 40px;
$tooltip-arrow-size: 6px;

.NavigationItem {
    display: block;
    position: relative;
}

.Row {
    align-items: center;
    border-left: 3px solid #354052;
    border-right: 3px solid #354052;
    color: #a8bdd5;
    display: flex;
    font-family: 'Frutiger', sans-serif;
    font-size: 16px;
    gap: 14px;
    height: 56px;
    letter-spacing: 0;
    line-height: 1.4;
    padding: 0 16px;
    user-select: none;
    cursor: pointer;

    &:hover,
    .NavigationItemActive & {
        color: #fff;
        background: #2a3342;
        border-left: 3px solid #a8bdd5;
        border-right: 3px solid #2a3342;
    }

    .NavigationItemOpen & {
        color: #fff;
    }

    .NavigationItemDisabled & {
        pointer-events: none;
        opacity: 0.24;
    }

    .NavigationItemLight & {
        background-color: rgba(#dae5f3, 0.5);
        color: #354052;
        border-right: none;
    }

    .NavigationItemLight &:hover {
        background-color: rgba(#dae5f3, 0.4);
    }

    .NavigationItemLight.NavigationItemActive & {
        background-color: #dae5f3;
        border-left: 3px solid #354052;
    }
}

.NavigationItemRestricted {
    .Row {
        pointer-events: none;
    }

    .Row > :not(.RightIcon) {
        opacity: 0.24;
    }
}

.NavigationItemIcon {
    height: 24px;
    width: 24px;
    flex-shrink: 0;
}

.RightIcon {
    height: 24px;
    margin-left: auto;
    width: 24px;
}

.RightIconRestrictedContainer {
    flex-shrink: 0;

    height: 24px;
    width: 24px;

    padding: 0 5px;

    border-radius: 50%;
    background-color: #665e47;
}

.RightIconRestricted {
    width: 100%;
    color: #fff;
}

.Tooltip {
    background-color: #354052;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    color: #fff;
    font-family: 'Frutiger', sans-serif;
    font-size: 16px;
    height: $tooltip-height;
    letter-spacing: 0;
    line-height: $tooltip-height;
    white-space: nowrap;
    padding: 0 16px;

    &::before {
        border-bottom: $tooltip-arrow-size solid transparent;
        border-right: $tooltip-arrow-size solid #354052;
        border-top: $tooltip-arrow-size solid transparent;
        box-sizing: border-box;
        content: '';
        display: block;
        height: 0;
        left: 16px - $tooltip-arrow-size;
        position: absolute;
        top: $tooltip-height * 0.5 - $tooltip-arrow-size;
        width: 0;
    }
}
