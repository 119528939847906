@use '../../styles/typo' as typo;

.EmailServiceGroup {
    break-inside: avoid-column;
    margin-bottom: 15px;
}

.EmailServiceGroupLabel {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #354052;
    margin-bottom: 8px;
    font-size: 16px;
}

.EmailServiceGroupName {
    flex-basis: min-content;
}

.EmailServiceGroupIcon {
    color: #6994bb;
    cursor: pointer;
    display: block;
    width: 16px;
    height: 16px;
    margin-left: 1rem;
    span {
        display: block;
        width: 16px;
        height: 16px;
    }
}

.EmailServiceGroupIcon:hover .EmailServiceTooltip {
    display: block;
}

.EmailServiceTooltipWrapper {
    position: absolute;
}

.EmailServiceTooltip {
    display: none;
    z-index: 9;
    background-color: #6994bb;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    color: #fff;
    border-radius: 4px;
    padding: 8px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    position: absolute;
    left: 32px;
    width: 200px;
    top: -28px;
    &::before {
        position: absolute;
        box-sizing: content-box;
        content: '';
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-right-color: #6994bb;
        right: 100%;
        top: 10px;
    }
}

.EmailServicesList {
    padding: 0;
    column-count: 2;
    position: relative;

    .EmailServiceCheckBox {
        @include typo.content_1;
        line-height: 16px;

        &Small {
            @include typo.content_3;
        }
    }

    .LastModifiedNote {
        margin-top: 0.125rem;
        @include typo.content_3;
    }

    .EmailServiceContainer {
        display: flex;
        margin-bottom: 0.75rem;
    }
}

.EmailServicesListItem {
    list-style: none;
    padding: 0 0 0.25rem 0;
    @include typo.content_1;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &Small {
        @include typo.content_3;
        padding-left: 1rem;
    }

    & > .IconWrapper {
        display: inline-flex;
        height: 24px;
        margin-right: 0.5rem;
        flex-shrink: 0;
        align-self: flex-start;
        align-items: center;

        .EnabledIcon {
            color: #006008;
            height: 1rem;
            width: 1rem;
        }

        .DisabledIcon {
            color: var(--color-red);
            height: 1rem;
            width: 1rem;
        }
    }
}

.EmailServiceListItemText {
    flex-basis: min-content;
}