@use '../../styles/typo' as typo;

.LocalErrorBoundary {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 1rem;
    @include typo.content_1;

    & > .ErrorIcon {
        @include typo.content_1;
    }

    &Multiline {
        height: 100%;
        flex-direction: column;
        justify-content: center;

        & > .ErrorIcon {
            width: 100%;
            flex-basis: 80px;
        }

        & > .ErrorMessage {
            @include typo.content_1;
        }
    }

    & > .ErrorMessage {
        @include typo.content_1;
    }
}
