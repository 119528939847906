@use '../../styles/typo.scss' as typo;

.EditContactInformationForm {
    & h2 {
        @include typo.subtitle_1;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 1rem;
    }
}

.CompanyInfoField {
    & input,
    & textarea {
        padding: 1rem 1.5rem;
        height: auto;
    }
}

.Form {
    margin: 1.5rem 0;

    & > p {
        @include typo.content_1;
    }
}

.FormRow {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    margin-bottom: 0.5rem;
}

.FormCellMedium {
    grid-column: span 2;
}

.FormCellFull {
    grid-column: span 4;
}

.FormCellTitle {
    grid-column: 2 / -1;
}

.FormButtons {
    margin-top: 1rem;
    width: 75%;
    display: flex;
    gap: 1rem;
    justify-content: stretch;

    & > div:first-child:last-child {
        max-width: 210px;
    }
}
