.ShippingInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    & .TerminalPickerContainer {
        width: 264px;
        flex-shrink: 0;
    }

    & .ArrowIcon {
        height: 16px;
        width: 16px;
    }
}

.OtherInfoInput {
    margin-bottom: 1.5rem;
    width: 100%;
    min-height: 100px;
}
