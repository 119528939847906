@use '../../styles/typo' as typo;

.Field {
    display: inline-block;
    position: relative;
    margin-bottom: 0.5rem;

    &AsBlock {
        display: block;

        & + & {
            margin-top: 0.5rem;
        }
    }
}

.Label {
    @include typo.content_3;

    &.RequiredLabel:not(:empty)::after {
        content: '*';
        color: #e30031;
        margin-left: 0.125rem;
    }
}

.ControlContainer {
    position: relative;
}

.ErrorMessage {
    color: white;
    background-color: var(--color-red);
    font-family: 'Frutiger', sans-serif;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 11px;
    padding: 2px 4px;
    position: absolute;
    right: 2px;
    border-radius: 2px;
}

.ErrorIndicator {
    color: var(--color-red);
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > .ErrorIcon {
        width: 16px;
        height: 16px;
    }
}

.ErrorArrow {
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translateX(50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid var(--color-red);
}
