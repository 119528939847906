@use '../../../styles/typo' as typo;

.CheckBox {
    @include typo.content_3;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: flex-start;

    input {
        flex-shrink: 0;
        flex-grow: 0;
        margin-right: 1rem;
        -webkit-appearance: none;
        appearance: none;
        font: inherit;
        color: white;
        background-color: white;
        border: 1px solid #cfd1d6;
        width: 1rem;
        height: 1rem;
        border-radius: 0.15em;
        transform: translateY(-0.075em);
        display: grid;
        place-content: center;

        &:checked {
            background-color: var(--color-primary);
            border-color: var(--color-primary);
        }

        &::before {
            content: '';
            width: 0.65rem;
            height: 0.65rem;
            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
            transform: scale(0);
            transform-origin: bottom left;
            background-color: white;
        }

        &:checked::before {
            transform: scale(1);
        }
    }

    &.ReadOnly {
        input {
            border-color: #f5f7fb;
            background-color: #f5f7fb;
        }

        input::before {
            background-color: #a0a0a1;
        }
    }

    a {
        color: var(--color-primary);

        &:hover {
            color: var(--color-red);
        }
    }
}
