.HeaderDesktop {
    align-items: center;
    background: #fff;
    box-shadow: 0 3px 8px 0 rgba(53, 64, 82, 0.08);
    display: flex;
    gap: 1rem;
    height: var(--header-height);
    justify-content: flex-start;
    padding: 0 24px 0 0;
    max-width: 100vw;
}

.LogoContainer {
    padding: 0.875rem 1rem;
    width: 16rem;
    flex-shrink: 0;
    align-self: stretch;
    display: flex;
    align-items: center;

    & > .Logo {
        max-height: 100%;
    }
}

.Tabs {
    display: flex;
    margin-right: auto;
    overflow-x: auto;
}

.Tab {
    align-items: center;
    flex-shrink: 0;
    border-bottom: 2px solid #fff;
    border-top: 10px solid #fff;
    box-sizing: border-box;
    color: #354052;
    cursor: pointer;
    display: flex;
    font-family: 'Frutiger', sans-serif;
    font-size: 16px;
    gap: 16px;
    height: 72px;
    justify-content: space-between;
    letter-spacing: 0;
    line-height: 22px;
    opacity: 0.5;
    padding: 0 24px;
    text-align: center;
    text-transform: uppercase;
    user-select: none;

    &Active {
        border-bottom-color: var(--color-primary);
        opacity: 1;
    }

    &Icon {
        height: 16px;
        width: 16px;
    }
}

.Search {
    align-items: center;
    border-left: 1px solid #e6e8ea;
    border-right: 1px solid #e6e8ea;
    color: #354052;
    display: flex;
    font-family: 'Frutiger', sans-serif;
    font-size: 16px;
    gap: 24px;
    height: 72px;
    justify-content: space-between;
    letter-spacing: 0;
    line-height: 72px;
    opacity: 0.5;
    padding: 0 24px;

    &Icon {
        height: 24px;
        width: 24px;
    }
}

.Notifications {
    align-items: center;
    background: #fff;
    border-radius: 50%;
    color: #354052;
    cursor: pointer;
    display: flex;
    height: 32px;
    justify-content: center;
    position: relative;
    user-select: none;
    width: 32px;

    &:hover {
        background: var(--color-primary);
        color: #fff;
    }
}

.BellIcon {
    height: 24px;
    width: 24px;
}

.BellPile {
    background: #e30031;
    border-radius: 50%;
    color: #fff;
    display: block;
    font-family: 'Frutiger', sans-serif;
    font-size: 14px;
    height: 16px;
    letter-spacing: 0;
    line-height: 16px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 16px;
}

.ProfileIcon {
    flex-shrink: 0;
    margin-left: auto;
    background: var(--color-primary);
    border: 2px solid #fff;
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;
    height: 32px;
    user-select: none;
    width: 32px;

    &:hover {
        border-color: var(--color-primary);
    }
}
