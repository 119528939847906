@use '../../styles/typo.scss' as typo;

.NewsletterForm {
    & > p {
        @include typo.content_1;
        margin-bottom: 1.5rem;
    }

    & label {
        width: 50%;
    }
}

.FormButtons {
    margin-top: 1rem;
    width: 75%;
    display: flex;
    gap: 1rem;
    justify-content: stretch;

    & > div:first-child:last-child {
        max-width: 210px;
    }
}
