.Gallery {
    position: relative;
    display: grid;
}

.GalleryItem {
    grid-column-start: 1;
    grid-row-start: 1;
    overflow: hidden;

    & [data-image-container],
    & [data-video-container] {
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
        margin-bottom: 2rem;
    }

    & [data-title],
    & [data-description] {
        opacity: 0;
        transition-duration: 0.3s;
        transition-property: opacity;
    }
}

.Selected {
    & [data-title],
    & [data-description] {
        opacity: 1;
    }
}

.SlideInFromLeft {
    & [data-image-container],
    & [data-video-container] {
        animation-name: slideInFromLeft;
    }
}

.SlideInFromRight {
    & [data-image-container],
    & [data-video-container] {
        animation-name: slideInFromRight;
    }
}

.SlideOutToLeft {
    & [data-image-container],
    & [data-video-container] {
        animation-name: slideOutToLeft;
    }
}

.SlideOutToRight {
    & [data-image-container],
    & [data-video-container] {
        animation-name: slideOutToRight;
    }
}

.Hidden {
    & [data-image-container],
    & [data-video-container] {
        transform: translate(-100%);
    }
}

.GalleryControllers {
    box-sizing: border-box;
    height: 0;
    padding-bottom: 56.25%;
    position: absolute;
    top: 0;
    width: 100%;
}

.Arrow {
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    border-radius: 5px;
    width: 1.6rem;
    height: 1.6rem;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    &[data-direction='prev'] {
        left: 1rem;
    }

    &[data-direction='next'] {
        right: 1rem;
    }
}

.BulletsContainer {
    width: 100%;
    position: absolute;
    top: 100%;
    display: flex;
    justify-content: center;
    height: 2rem;
}

.Bullet {
    background: none;
    border: none;
    color: rgba(0, 0, 0, 0.3);
    padding: 0 0.5rem;
    display: block;
    font-size: 1.6rem;
    line-height: 2rem;
    cursor: pointer;

    &:disabled {
        font-size: 2rem;
        color: black;
        cursor: default;
    }
}

@keyframes slideInFromLeft {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes slideInFromRight {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes slideOutToLeft {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

@keyframes slideOutToRight {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(100%);
    }
}
