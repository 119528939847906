.OnlineBooking {
    width: 100%;
    align-self: stretch;
    display: flex;

    &Frame {
        width: 100%;
        border: none;
    }

    &Mobile {
        height: 750px;
    }
}
