@use '../../styles/typo' as typo;

.CreateEmployeeForm {
    margin: 1.5rem;

    & > div[class*='ButtonContainer'] {
        margin-top: 2rem;
        width: 33%;
    }
}

.FormRow {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    margin-bottom: 0.5rem;
}

.FormCellMedium {
    grid-column: span 2;
}

.FormCellFull {
    grid-column: span 4;
}

.FormCellTitle {
    grid-column: 2 / -1;
}
