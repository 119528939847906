@use '../../styles/typo' as typo;

.NotAuthorizedErrorBoundary {
    display: grid;
    align-content: center;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    flex-grow: 1;
    @include typo.content_1;

    .Logo {
        width: 100%;
    }

    & > .PasswordIcon {
        @include typo.content_1;
        height: 80px;
        width: 80px;
        margin: 0 auto;
    }
}
