@use '../../styles/typo' as typo;

.TOTPWall {
    @include typo.content_1;
    position: relative;
    background-position: top center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-size: 0;

    &::before {
        z-index: -1;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-position: inherit;
        background-image: inherit;
        background-size: contain;
        background-repeat: no-repeat;
        filter: blur(8px);
    }
}

.ModalContent {
    margin: 0 1.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 350px;
}

.QRCodeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2.5rem auto;

    & > img {
        margin-bottom: 1rem;
    }
}

.SetupButton {
    margin-top: 1rem;
    max-width: 300px;
}

.ResetHelper {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    @include typo.content_3;
}

.ContentDescription {
    margin-bottom: auto;
    align-self: flex-start;
}

.FormContainer {
    margin-bottom: auto;
    text-align: center;
    position: relative;
}

.PasswordForm {
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.Input {
    box-sizing: border-box;
    height: 52px;
    width: 39px;
    border: 1px solid #cfd1d6;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: inset 1px 1px 4px 0 rgba(0, 0, 0, 0.16);
    text-align: center;
    font-size: 24px;
    color: #354052;

    &:disabled {
        background-color: #f5f7fb;
        border: none;
        box-shadow: none;
    }

    .Error & {
        color: var(--color-red);
    }
}

.ErrorMessage {
    position: absolute;
    border-radius: 2px;
    background-color: #eb1a3b;
    color: #fff;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 12px;
    padding: 3px 5px;
    bottom: -2rem;
    transform: translateX(-50%);
}

.FeedbackMessage {
    @include typo.headline_2;
    display: block;
    max-width: 200px;
    text-align: center;
    margin: 1.25rem 0;
}

.CheckMarkIconContainer {
    border-radius: 100%;
    background-color: #6da39d;
    height: 6rem;
    width: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    .CheckMarkIcon {
        height: 3rem;
        width: 3rem;
    }
}

.ButtonContainer {
    min-width: 144px;
}
