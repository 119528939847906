.PhoneNumberPicker {
    display: flex;
    gap: 1rem;
}

.PhoneNumberPicker > div {
    flex-basis: 50%;
    display: flex;

    & > label:first-child {
        flex-basis: 70%;
        margin-right: 0.5rem;
    }

    & > label:last-child {
        flex-basis: 30%;
        margin-left: 0.5rem;
    }

    & > label:last-child:first-child {
        flex-basis: calc(70% - 1rem);
        margin: 0;
    }
}

.Separator {
    align-self: flex-end;
    line-height: 32px;
    margin-bottom: 0.5rem;
}
