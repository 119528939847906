.ContactSummary {
  align-items: center;
  display: flex;
  gap: 32px;
  padding: 24px;

  & > .Image {
    align-self: flex-start;
    border-radius: 50%;
    height: 80px;
    width: 80px;
  }

  & > .Text {
    flex-grow: 1;
  }

  & > .ArrowIcon {
    flex-shrink: 0;
  }
}


.FlagIcon {
  gap: 0.5rem;
  display: flex;
}

.ContentSection {
  padding: 0 24px;
}

.ContentText {
  color: var(--color-text-primary);
}


.ContactDetail {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.ContactIcons {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  color: var(--color-primary);

  &:hover {
    color: var(--color-red);
  }

  & > a {
    color: var(--color-primary);

    &:hover {
      color: var(--color-red);
    }
  }
}

.ContactAnchor {
  color: var(--color-primary);
}

.LinkTitle {
  color: var(--color-text-primary);
  font-size: 18px;
  font-weight: bold;
  line-height: 32px;
  letter-spacing: 0;
  text-transform: uppercase;
}

.LinkSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.LinkHeading {
  color: var(--color-text-primary);
  font-size: 18px;
  font-weight: bold;
  line-height: 32px;
  letter-spacing: 0;
}

.LinkBox {
  background-color: var(--color-page-bg);
  padding: 10px 20px;
  cursor: pointer;
}

.LinkBoxLast {
  margin-bottom: 1rem;
}

.LinkBox:hover div div button {
  color: var(--color-red);
}

.Link {
  color: var(--color-text-primary);
}

.ExportCopyButton {
  color: var(--color-primary);
  background-color: var(--color-page-bg);
  border: none;

  &:hover {
    color: var(--color-red);
    background-color: var(--color-page-bg);
  }
}

