@use '../../styles/typo' as typo;
@use '../Page/Page.module' as page;

.Downloads {
    @include typo.content_1;
    width: 100%;
}

.PageTitle {
    margin-bottom: 1rem;
}

.TableContainer {
    @include page.page;
    padding: 1rem;
    width: initial;
}

.CategoryTag {
    /* hack, otherwise the table cell is higher than expected */
    display: inline;
}

.DownloadLink {
    color: var(--color-primary);

    &:hover,
    &:focus {
        color: #8caac6;
    }

    &:active {
        color: #537da3;
    }

    & .DownloadIcon {
        width: 20px;
    }
}
