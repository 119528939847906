@use '../../styles/typo' as typo;

.Steps {
    background: #f5f7fb;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 1rem 0;

    &Two {
        grid-template-columns: repeat(2, 1fr);
    }

    &Three {
        grid-template-columns: repeat(3, 1fr);
    }

    .Item {
        --lane-color: #e1e3e9;
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 8px;
        @include typo.content_1;

        &:last-child .IconLane::after {
            content: none;
        }

        &Ready {
            --lane-color: #6da39d;
        }

        &Active .Icon {
            background-color: #6994bb;
        }

        &Ready .Icon {
            background-color: #6da39d;

            .ReadyIcon {
                height: 16px;
                width: 16px;
            }
        }
    }

    .Icon {
        align-items: center;
        background: #e1e3e9;
        border-radius: 50%;
        color: #fff;
        display: inline-flex;
        height: 32px;
        justify-content: center;
        width: 32px;

        &Lane {
            position: relative;
            text-align: center;
            width: 100%;
            z-index: 1;

            &::after {
                background: var(--lane-color);
                content: '';
                display: block;
                height: 1px;
                left: 50%;
                position: absolute;
                right: -50%;
                top: 50%;
                z-index: -1;
            }
        }
    }
}
