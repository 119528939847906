@use '../../styles/typo' as typo;

.PrivacyForm {
    & h2 {
        @include typo.subtitle_1;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    & > h2::after {
        content: '*';
        color: var(--color-red);
    }
}

.SettingsForm {
    & > p {
        @include typo.content_3;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 1rem;
    }
}

.CheckBox {
    margin: 0.5rem 0;
}

.FormButtons {
    margin-top: 1rem;
    width: 75%;
    display: flex;
    gap: 1rem;
    justify-content: stretch;

    & > div:first-child:last-child {
        max-width: 210px;
    }
}

.Note {
    margin-top: 1rem;

    & > a {
        @include typo.link_1;
        font-size: inherit;
        line-height: inherit;
    }
}
