.DownloadLink {
    text-decoration: none;

    & .DownloadIcon {
        color: var(--color-primary);
    }

    &:hover .DownloadIcon,
    &:focus .DownloadIcon {
        color: #8caac6;
    }

    &:active .DownloadIcon {
        color: #537da3;
    }
}
