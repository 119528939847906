@font-face {
    font-family: Frutiger;
    font-weight: 100 400;
    src: url('fonts/Frutiger/FrutigerLTProCondensed.woff2') format('woff2'),
        url('fonts/Frutiger/FrutigerLTProCondensed.woff') format('woff');
}

@font-face {
    font-family: Frutiger;
    font-weight: 500 700;
    src: url('fonts/Frutiger/FrutigerLTProBoldCn.woff2') format('woff2'),
        url('fonts/Frutiger/FrutigerLTProBoldCn.woff') format('woff');
}

@font-face {
    font-family: Frutiger;
    font-weight: 800 900;
    src: url('fonts/Frutiger/FrutigerLTProBlackCn.woff2') format('woff2'),
        url('fonts/Frutiger/FrutigerLTProBlackCn.woff') format('woff');
}

:root {
    --color-page-bg: #f5f6f8;
    --color-red: #eb1a3b;
    --color-primary: #6994bb;
    --color-text-primary: #354052;
    --color-hairline: #e6e8ea;
    --header-height: 72px;
    --header-mobile-height: 56px;
    --warning-bg-color: #ffedb5;
    --warning-text-color: #665e48;
    --error-bg-color: #fcd3d2;
    --error-text-color: #811211;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    margin: 0;
}

html,
body {
    height: 100%;
}

body {
    background: var(--color-page-bg);
    font-family: 'Frutiger', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
}

img,
picture,
video,
canvas,
svg {
    display: block;
    max-width: 100%;
}

input,
button,
textarea,
select {
    font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    overflow-wrap: break-word;
}

.multiline-translation {
    white-space: pre-line;
}

html, body, #root, .App {
    height: 100%;
}
