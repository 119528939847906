.HeaderMobile {
    position: fixed;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 1rem;

    height: var(--header-mobile-height);

    padding: 0 19px 0 0;
    background: #fff;
    box-shadow: 0 3px 8px 0 rgba(53, 64, 82, 0.08);

    &LogoContainer {
        flex-shrink: 0;
        display: flex;
        align-self: stretch;
        align-items: center;

        width: 10rem;

        padding: 0.875rem 1rem;
    }

    &Logo {
        max-height: 100%;
    }

    &NavIcon {
        height: 30px;
        width: 30px;

        color: #909191;
        cursor: pointer;
    }
}
