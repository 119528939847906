@use '../../../styles/typo' as typo;
@use '../Input/Input.module' as Input;

.SelectContainer {
    @include Input.input_field;
    position: relative;

    --bg-color-selected: #8caac6;
    --bg-color-hover: #f5f7fb;

    &.WithError {
        border-color: var(--color-red);

        &:focus {
            outline-color: rgba(var(--color-red), 0.3);
        }
    }
}

.ErrorIndicator {
    right: 1.75rem;
}
