@use '../../styles/typo' as typo;
@use '../Page/Page.module' as page;

.Container {
  @include typo.content_1;
  width: 100%;
}

.PageTitle {
  margin-bottom: 1rem;
}

.InfoMessage {
  margin-top: 0.3rem;
  font-size: .75rem;
  line-height: 1.5;
  color: #354052;
  margin-bottom: 1rem;
}

.Heading {
  margin-bottom: 1rem;
}

.Section {
  @include page.page;
  padding: 1rem;
  width: initial;
}

.AlertSection {
  margin-top: 1rem;
}

.UpsertBookletForm {
  @include typo.content_1;
  width: 100%;
  margin: 1.5rem 0;

  & > div[class*='ButtonContainer'] {
    margin-top: 2rem;
    width: 33%;
  }
}

.TwoSections {
  display: grid;
  grid-template-columns: 75% 24%;
  gap: 1rem;
}

.FormRow {
  display: grid;
  gap: 1rem;
  margin-bottom: 0.5rem;
  margin-left: 0;
  margin-right: 0;
}


.FormRowGrid2 {
  grid-template-columns: repeat(2, 1fr);
}

.FormRowGrid3 {
  grid-template-columns: repeat(3, 1fr);
}

.FromStyle {
  width: 48%;
  margin-right: 2%;
}


.ToStyle {
  width: 50%;
}


.FormCellMedium {
  grid-column: span 2;
}

.FormCellFull {
  grid-column: span 4;
}

.FormCellTitle {
  grid-column: 2 / -1;
}

.DivBookletButtons {
  display: flex;
  justify-content: center;
  padding-top: 24px;
  gap: 1rem;


  & > div {
    width: 200px;
  }
}

@media (max-width: 1400px) {
  .DivBookletButtons {
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }
}


.Child1 {
  order: 1;
}

.Child2 {
  order: 2;
}

.Child3 {
  order: 3;
}

.Child4 {
  order: 4;
}


.Child5 {
  order: 5;
}

.Child6 {
  order: 6;
}

.Child7 {
  order: 7;
}

.Child8 {
  order: 8;
}

.Child9 {
  order: 9;
}

.ChildSpan2 {
  grid-column-end: span 2;
}

.ChildSpan3 {
  grid-column-end: span 3;
}

.CreatePDF {
  width: 100%;

  & > div {
    & > button {
      padding: 0 25px;
      white-space: nowrap;
    }
  }
}

@media (max-width: 1400px) {
  .CreatePDF {
    & > div {
      width: 100%;
    }
  }
}
