@use '../../styles/typo' as typo;

.ChangeEmailForm {
    & > form {
        margin-bottom: 0.5rem;
        display: flex;
        gap: 1rem;

        & > label {
            flex-basis: calc(50% - 0.5rem);
        }
    }
}

.FormButtons {
    margin-top: 1rem;
    width: 75%;
    display: flex;
    gap: 1rem;
    justify-content: stretch;

    & > div:first-child:last-child {
        max-width: 210px;
    }
}
