@use '../../styles/typo' as typo;

.Card {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    padding: 1.5rem 0;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    &TitleContainer,
    &Content,
    &Footer {
        padding: 0 2rem;
    }

    &TitleContainer {
        position: relative;
    }

    &Title {
        @include typo.content_2_uppercase;
    }

    &CollapsibleOpenedIcon,
    &CollapsibleClosedIcon {
        display: none;
    }

    &Content {
        flex-grow: 1;

        &Wide {
            padding: 0;
        }
    }

    &Footer {
        margin-top: auto;
    }
}

.Card {
    &Collapsible:not(&Collapsed) &CollapsibleOpenedIcon,
    &Collapsible#{&}Collapsed &CollapsibleClosedIcon {
        display: block;
        position: absolute;
        top: 50%;
        left: 2rem;
        transform: translateY(-50%);
    }

    &Collapsible &TitleContainer {
        padding-left: 3.5rem;
    }

    &Collapsible &Title {
        user-select: none;
        cursor: pointer;
    }

    &Collapsed &Content,
    &Collapsed &Footer {
        display: none;
    }
}

.Card {
    &Mobile {
        gap: 0;
        padding: 0;
        border-top: 1px solid transparent;
        box-shadow: none;
    }

    // make sure borders don't collide
    &Mobile#{&}Collapsible#{&}Collapsed + &Mobile#{&}Collapsible:not(&Collapsed):not(:first-child) {
        border-top: 1px solid #e1e3e9;
    }

    &Mobile &TitleContainer {
        padding: 0.75rem 0;
        padding-left: 1rem;
    }

    &Mobile &Title {
        font-weight: 400;
    }

    &Mobile &Footer {
        padding: 0.75rem 2rem;
    }

    &Mobile#{&}Collapsible:not(&Collapsed) &Title {
        font-weight: 700;
    }

    &Mobile#{&}Collapsible &TitleContainer {
        padding-left: 2.25rem;
    }

    &Mobile#{&}Collapsible:not(&Collapsed) &CollapsibleOpenedIcon,
    &Mobile#{&}Collapsible#{&}Collapsed &CollapsibleClosedIcon {
        left: 1rem;

        height: 16px;
        width: 16px;
    }

    &Mobile#{&}Collapsible:not(&Collapsed) &Title {
        font-weight: 700;
    }

    &Mobile#{&}Collapsible:not(&Collapsed) &Footer {
        border-bottom: 1px solid #e1e3e9;
    }
}
