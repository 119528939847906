@use '../../styles/typo' as typo;

.Backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #2a3342b3;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.Modal {
    width: 672px;
    background-color: #fff;
    border-radius: 2px;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 16px 12px rgba(0, 0, 0, 0.16);
}
.Modal[data-size="wide"] {
    width: 800px;
}
.Modal[data-size="extra-wide"] {
    width: 1076px;
}

.ModalHeader {
    width: 100%;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: space-between;
    align-items: flex-start;

    & > h1 {
        @include typo.headline_3;
        margin: 1rem 1.5rem;
    }

    & > button {
        margin: 0.75rem;
        width: 1.5rem;
        height: 1.5rem;
        padding: 0;
        border: none;
        background: none;
        cursor: pointer;
    }
}

.ModalBody {
    overflow-y: auto;
}
