@use '../../styles/typo' as typo;
@use '../Page/Page.module' as page;

.Booklets {
  @include typo.content_1;
  width: 100%;
}

.PageTitle {
  margin-bottom: 1rem;
}

.SectionCreate {
  text-align: right;
  margin-bottom: 1rem;
}

.TableContainer {
  @include page.page;
  padding: 1rem;
  width: initial;
}

.Secondary {
  background-color: #fff;
  border-color: #cfcfd0;
  color: var(--color-primary);

  &:hover,
  &:focus {
    background-color: #f5f6f8;
  }

  &:active {
    background-color: #dae5f3;
    border-color: #a8bdd5;
  }

  &:disabled {
    background-color: #fff;
    border-color: #cfcfd0;
    cursor: default;
    opacity: 0.5;
  }
}

.lastCellActions {
  display: flex;
  gap: 0.5rem;
}

.lastCell {
  max-width: 40%;
  width: 35%;
}

.EmptyHeadline {
  font-size: 1.375rem;
  line-height: 1.45;
  margin-bottom: 0.5rem;
}

.EmptySubHeadline {
  color: #9A9FA8;
  font-size: 1rem;
  line-height: 1.5;
}

.ButtonHeight {
  height: unset;
  min-height: 2rem;
}