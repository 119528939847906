.Faq {
    border-bottom: 1px solid #e1e3e9;
    user-select: none;
    color: #354052;
    font-family: 'Frutiger', sans-serif;
    font-size: 16px;
    line-height: 22px;
}

.Summary {
    align-items: flex-start;
    cursor: pointer;
    display: flex;
    gap: 12px;
    letter-spacing: 0;
    padding: 16px 24px;
}

.Toggle {
    height: 16px;
    width: 16px;
    margin-top: 0.2rem;
    flex-shrink: 0;
}

.Detail {
    display: none;
    padding: 0 24px 24px 52px;
}

.Expanded {
    .Summary {
        font-weight: bold;
    }

    .Detail {
        display: block;
    }
}
