@use '../../../styles/typo' as typo;

.ContentSection {
  padding: 0 24px;
}

.FormSection {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
}

.FormDivGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}

.PreferredRouteDiv {
  margin-top: 2.5rem;
}

.HeadingGroup {
  margin: 0.5rem 0;
}

.FooterSection {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  justify-content: end;
}

.AddTerminalButton {
  text-decoration: underline;
  color: var(--color-primary);
  cursor: pointer;
  border: none;
  background-color: white;
  text-align: left;
}

.AddTerminalButton:hover {
  color: var(--color-red);
}

.Label {
  @include typo.content_3;
}

.ViaNotViaTerminals {
  display: flex;
  justify-content: center;
  gap: 0.2rem;

  & > label {
    width: 100%;
  }
}