:root {
    --grid-columns: 2;
    --grid-column-width: 1fr;
}

.Grid {
    display: grid;
    gap: 2rem;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(var(--grid-columns), var(--grid-column-width));

    &Mobile {
        display: block;
    }
}
