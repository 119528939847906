@use '../../styles/typo' as typo;

.TerminalPicker {
    border: 1px solid #cfcfd0;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 24px;

    & > h1 {
        align-self: flex-start;
        @include typo.content_2;

        &::after {
            content: '*';
            color: #e30031;
            font-weight: normal;
        }
    }
}

.Divider {
    text-align: center;
    opacity: 0.5;
    margin-top: 0.25rem;
    @include typo.content_3;
}
