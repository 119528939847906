@mixin headline_0 {
    color: #354052;
    font-size: 28px;
    font-weight: bold;
    line-height: 40px;
}

@mixin headline_1 {
    color: #354052;
    font-size: 26px;
    font-weight: bold;
    line-height: 36px;
}

@mixin headline_2 {
    color: #354052;
    font-size: 26px;
    font-weight: normal;
    line-height: 36px;
}

@mixin headline_3 {
    color: #354052;
    font-size: 22px;
    font-weight: normal;
    line-height: 32px;
}

@mixin headline_4 {
    color: #354052;
    font-size: 20px;
    font-weight: normal;
    line-height: 32px;
}

@mixin headline_5 {
    color: #354052;
    font-size: 18px;
    font-weight: bold;
    line-height: 32px;
}

@mixin subtitle_1 {
    color: #354052;
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
}

@mixin content_1 {
    color: #354052;
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
}

@mixin content_1_light {
    color: #9a9fa8;
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
}

@mixin content_2 {
    color: #354052;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
}

@mixin content_2_uppercase {
    color: #354052;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    text-transform: uppercase;
}

@mixin content_3 {
    color: #354052;
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
}

@mixin link_1 {
    color: var(--color-primary);
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;

    &:hover {
        color: var(--color-red);
    }
}

@mixin button_1 {
    color: var(--color-primary);
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
}
