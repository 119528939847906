.ServiceHotline {
    background-color: #678cbb;
    color: white;
    padding: 1.5rem;
    padding-bottom: 4rem;
    position: relative;

    &Headline {
        font-size: 1.5rem;
    }

    &Note {
        font-size: 1rem;
    }

    a {
        color: white;
        display: block;
        text-decoration: none;
        font-weight: bold;
        font-size: 2rem;
        margin-bottom: 0.25rem;

        &:hover {
            text-decoration: underline;
        }
    }

    & > img {
        position: absolute;
        margin-top: 1rem;
        right: 50%;
        transform: translateX(50%);
    }
}

.ServiceHotline {
    &Mobile &Headline {
        margin-bottom: 0.6rem;
        font-size: 1.5rem;
    }

    &Mobile a {
        font-size: 2.5rem;
    }
}
