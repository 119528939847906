@use '../../styles/typo.scss' as typo;

.ButtonsContainer {
    padding: 1.5rem;
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;

    .ButtonWrapper {
        width: 100px;
    }
}

.ErrorMessage {
    margin: 0 1.5rem 1.5rem 1.5rem;
    width: initial;
}

.ReadyStage {
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;

    .CheckMarkIconContainer {
        border-radius: 100%;
        background-color: #6da39d;
        height: 6rem;
        width: 6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;

        .CheckMarkIcon {
            height: 3rem;
            width: 3rem;
        }
    }

    .ConfirmationText {
        @include typo.headline_2;
        display: block;
        width: 300px;
        text-align: center;
    }
}
