.Navigation {
    background: #354052;
    width: 272px;
    display: flex;
    flex-direction: column;

    &Mobile {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: auto;

        z-index: 100;
    }

    &Mobile.Collapsed {
        display: none;
    }

    &Mobile .Button {
        padding-top: 0;
    }

    &MobileCloseContainer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        align-content: center;

        margin: 16px 0 20px;
        padding: 0 22px;

        text-align: right;
        z-index: 1;
    }

    &MobileClose {
        display: inline-block;
        color: #a8bdd5;
        cursor: pointer;

        &:hover {
            color: #fff;
        }
    }

    &BookingIcon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &BookingIconMobile {
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.NavigationItems {
    position: relative;
}

.ScrollableArea {
    overflow-y: auto;

    &::-webkit-scrollbar {
        background: transparent;
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.5);
        border-radius: 10px;
        border-right: 2px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-button {
        display: none;
    }
}

.Button {
    position: relative;
    padding: 24px 16px;

    &WithIcon button {
        pointer-events: none;
        opacity: 0.24;
    }

    &Restricted > * {
        pointer-events: none;
        opacity: 0.24;
    }
}

.Bottom {
    box-shadow: -3px -3px 8px 0 rgba(0, 0, 0, 0.3);
    margin-top: auto;
}

.Toggle {
    justify-content: flex-end;
    border-top: 1px solid #2a3342;
    color: #a8bdd5;
    user-select: none;
    cursor: pointer;
    height: 56px;
    display: flex;
    align-items: center;
    padding: 0 19px;

    &:hover {
        color: #fff;
        background: #2a3342;
    }
}

.Collapsed {
    width: 64px;

    .Header {
        justify-content: center;
    }
}
